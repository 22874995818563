import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

let websocket = null;

function CheckingBCS() {
  const navigate = useNavigate();
  const [BCSDetected, setBCSDetected] = React.useState(false);
  const [BCSChecking, setBCSChecking] = React.useState(true);
  const stateRef = React.useRef({
    retryCount: 0,
  });
  
  const connectionType = sessionStorage.getItem("connectionType") || "secure"; // Default to secure connection

  // Event handler for WebSocket open
  const onOpen = () => {
    console.log("WebSocket connection established");
    websocket.close();
    navigate("/systemReady");
  };

  // Event handler for WebSocket error
  const onError = (e) => {
    console.error("WebSocket Error", e);
    websocket.close();
    if (stateRef.current.retryCount > 1) {
      console.log("BCS not detected after retries");
      setBCSChecking(false);
      setBCSDetected(false);
    }
  };

  // Event handler for WebSocket close
  const onClose = () => {
    console.log("WebSocket connection closed");
    if (!BCSDetected) {
      reconnect();
    }
  };

  // Function to attempt a reconnection
  const reconnect = () => {
    if (stateRef.current.retryCount <= 1) {
      connect();
    } else {
      console.log("Max retries reached. No more reconnection attempts.");
    }
  };

  // Function to establish a WebSocket connection
  const connect = () => {
    const protocol = connectionType === "secure" ? "wss://" : "ws://";
    const port = connectionType === "secure" ? ":32660" : ":32658";
    const wsHost = `${protocol}bcs.visualware.com${port}`;
    const fallbackHost = `${protocol}127.0.0.1${port}`;
    
    let selectedHost = stateRef.current.retryCount > 0 ? fallbackHost : wsHost;
    
    console.log(`Attempting WebSocket connection to ${selectedHost}`);
    stateRef.current.retryCount++;

    websocket = new WebSocket(selectedHost);

    // WebSocket event handlers
    websocket.onopen = onOpen;
    websocket.onerror = onError;
    websocket.onclose = onClose;

    // Log the readyState of the WebSocket
    console.log(`WebSocket readyState: ${websocket.readyState}`);
  };

  // Reload the page and retry the connection
  const reload = () => {
    if (websocket) {
      websocket.close();
    }
    navigate("/checkingBCS");
  };

  // Navigate back to the download page
  const goback = () => {
    if (websocket) {
      websocket.close();
    }
    navigate("/download");
  };

  // Effect hook to initiate the WebSocket connection on component mount
  useEffect(() => {
    if (!BCSDetected && stateRef.current.retryCount < 1) {
      console.log("Starting BCS check");
      const timer = setTimeout(() => {
        setBCSChecking(true);
        connect();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [BCSDetected]);

  return (
    <>
      <div className="my-[100px]">
        <h1 className="text-[42px] font-semibold text-left text-white">
          Checking, Please Wait! {BCSDetected}
        </h1>
        <p className="text-[15px] text-left text-white mt-2 font-normal">
          Checking for prerequisites....this will take a few seconds. Thank you
          for your patience.
        </p>
        {BCSChecking ? <div className="mt-7 loader"></div> : ""}
        {!BCSDetected && BCSChecking !== true ? (
          <>
            <div className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 mt-5" role="alert">
              <p className="font-bold text-[20px]">Looks like BCS is not Running!</p>
              <p className="text-md">
                The Connectivity test ran two tests. Both of these tests failed,
                which indicates that the BCS is either not running correctly or
                is not installed.
              </p>
            </div>
            <button onClick={goback} className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-16 duration-500">
              Go Back to BCS Download options
            </button>
            <br />
            <button onClick={reload} className="text-base font-semibold text-center text-white mt-11">
              Check for BCS again
            </button>

            <div className="my-[100px]">
              <h3 className="text-[17px] font-semibold text-left text-white">
                If the BCS has been installed and the connectivity test is still failing, please check the BCS support website or contact us.
              </h3>
              <div className="pl-5">
                <ul className="list-outside list-disc">
                  <li>
                    <a className="underline" href="https://www.visualware.com/bcs/support/index.html">BCS support website</a>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CheckingBCS;
